import { Injectable } from '@angular/core';
import _ from 'lodash';
import { ApiService } from 'src/app/api/api.service';
import { InfoService } from 'src/app/services/info.service';
import { ContactType, DraftAttachment, ConversationDraft, DraftType } from './conversations.service';

class WAMessage {
  public attachment?: DraftAttachment;
  private textEntry?: string;

  constructor (textEntry?: string, attachment?: DraftAttachment) {
    if (textEntry) this.textEntry = textEntry;
    if (attachment) this.attachment = attachment;
  }

  public get type(): DraftType {
    return this.attachment ? this.attachment.type : DraftType.Text;
  }

  public get text(): string {
    const documentAttachmentName = this.attachment && this.attachment.type === DraftType.Document ? this.attachment.name : null;
    return this.textEntry || documentAttachmentName || '';
  }
}

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  private agentsById: { [key: string]: any } = {};
  private fetchingLimit = 30;
  private externalLogTypes = [
    'text',
    'image',
    'audio',
    'video',
    'location',
    'contact',
    'document',
    'voice',
    'call',
  ];

  constructor(
    private apiService: ApiService,
    private infoService: InfoService,
  ) {
    this.loadAgentsByIds().catch(error => {});
    this.fetchingLimit = this.infoService.getWAInfos().conversationsLimitPerAgent || this.fetchingLimit;
  }

  public getConversations(): Promise<any> {
    return Promise.all([
      this.apiService.getRequest('/agent/conversations/?order=~updatedAt&status=new'),
      this.apiService.getRequest('/agent/conversations/?order=~updatedAt&status=open'),
      this.apiService.getRequest(`/agent/conversations/?order=~updatedAt&status=closed&limit=${this.fetchingLimit}`),
    ]).then(results => results.find((result:any) => !result.result)
        || { result: true, content: _.concat(...results.map((result:any) => result.content)) }
    ).catch(() => {});
  }

  public searchConversations(previewFilter = ''): Promise<any> {
    if (!previewFilter) return this.apiService.getRequest(`/agent/conversations/?order=~updatedAt&limit=${2 * this.fetchingLimit}`);
    return this.apiService.getRequest(`/agent/conversations/?order=~updatedAt&search=${encodeURIComponent(previewFilter)}`);
  }

  public getConversation(conversationId: string): Promise<any> {
    return this.apiService.getRequest(`/agent/conversations/${conversationId}`);
  }

  public openConversation(conversationId: string): Promise<any> {
    return this.apiService.putRequest(`/agent/conversations/${conversationId}/open`, {});
  }

  public closeConversation(conversationId: string): Promise<any> {
    return this.apiService.putRequest(`/agent/conversations/${conversationId}/close`, {});
  }

  public getConversationLogs(conversationId: string, limit: number, skip: number = 0): Promise<any> {
    return this.apiService.getRequest(`/agent/conversations/${conversationId}/logs?limit=${limit}&skip=${skip}`);
  }

  public getExternalConversationLogs(conversationId: string, limit: number, skip: number = 0): Promise<any> {
    const handledConversationLogTypesFilter = this.externalLogTypes.reduce((filter, handledType) => filter+`&type=${handledType}`, '');
    return this.apiService.getRequest(
      `/agent/conversations/${conversationId}/logs?limit=${limit}&skip=${skip}${handledConversationLogTypesFilter}`
    );
  }

  public getConversationLog(conversationId: string, conversationLogId: string): Promise<any> {
    return this.apiService.getRequest(`/agent/conversations/${conversationId}/logs/${conversationLogId}`);
  }

  public getAttachments(conversationId: string, limit: number, skip: number = 0): Promise<any> {
    return this.apiService.getRequest(`/agent/conversations/${conversationId}/attachments?limit=${limit}&skip=${skip}`);
  }

  public markConversationAsRead(conversationId: string): Promise<any> {
    return this.apiService.putRequest(`/agent/conversations/${conversationId}/seen`, {});
  }

  public sendWhatsApp(conversationId: string, draft: ConversationDraft): Promise<any> {
    if (!draft.attachments.length) return this.sendWhatsAppMessage(conversationId, new WAMessage(draft.text));

    return Promise.all(draft.attachments.map((attachment: DraftAttachment, attachmentIndex: number) => {
      if (attachmentIndex === 0) {
        if (attachment.isTextCompatible()) return this.sendWhatsAppMessage(conversationId, new WAMessage(draft.text, attachment));
        return Promise.all([
          this.sendWhatsAppMessage(conversationId, new WAMessage(draft.text)),
          this.sendWhatsAppMessage(conversationId, new WAMessage('', attachment)),
        ]);
      }
      return this.sendWhatsAppMessage(conversationId, new WAMessage('', attachment));
    }));
  }

  public sendSms(conversationId: string, draft: ConversationDraft): Promise<any> {
    return this.apiService.postRequest('/agent/channels/sms', {
      conversationId: conversationId,
      content: draft.text,
    });
  }

  public transferTo(conversationId: string, agentId: string): Promise<any> {
    return this.apiService.putRequest(`/agent/conversations/${conversationId}/transfer/${agentId}`, {});
  }

  public getAgentsByIds(): { [key: string]: any } {
    return this.agentsById;
  }

  public getAgentsForTransfer(conversationId: string): Promise<any> {
    return this.apiService.getRequest(`/agent/conversations/${conversationId}/agents/for/transfer`);
  }

  public getCustomer(customerId: string): Promise<any> {
    return this.apiService.getRequest(`/agent/crm/customers/${customerId}/?dataset=emails&dataset=phones`);
  }

  public updateCustomer(customer: any): Promise<any> {
    return this.apiService.putRequest(`/agent/crm/customers/${customer.id}/`, customer);
  }

  public createContact(customerId: string, contactType: ContactType, contact: any): Promise<any> {
    return this.apiService.postRequest(`/agent/crm/customers/${customerId}/${contactType}s/`, contact);
  }

  public deleteContact(customerId: string, contactType: ContactType, contact: any): Promise<any> {
    return this.apiService.deleteRequest(`/agent/crm/customers/${customerId}/${contactType}s/${contact.id}`);
  }

  public setContactAsPrimary(customerId: string, contactType: ContactType, contact: any): Promise<any> {
    return this.apiService.putRequest(`/agent/crm/customers/${customerId}/${contactType}s/${contact.id}/primary`, {});
  }

  public getConversationHistory(params: any): Promise<any> {
    return this.apiService.getRequest
    (`/agent/conversations/history/by/${params.id}?order=~updatedAt&skip=${params.skip}&limit=${params.limit}`);
  }

  public searchConversationHistory(params: any): Promise<any> {
    return this.apiService.getRequest(
      `/agent/conversations/history/by/${params.id}`
      + `?order=~updatedAt&skip=${params.skip}&limit=${params.limit}&search=${encodeURIComponent(params.searchText)}`
    );
  }

  public getCustomerTitles(): Promise<any> {
    return this.apiService.getRequest('/agent/crm/customers/titles');
  }

  public getFile(fileName: string): void {
    // TO DO when on admin side to allow download of recordings
    // return this.apiService.getRequest(`${fileName}?dl=1`),
    //   { headers: { access_token: this.loginService.accessToken!, responseType: 'arraybuffer' } }).toPromise().then((res: any) => {
    //   if (res.status === 200) {
    //     const data = new Blob([res.data], { type: '.mp3' });
    //     const _saveFileName = fileName.split('/');
    //     const _saveFileNameString = _saveFileName && _saveFileName.length ? _.last(_saveFileName) : fileName;
    //     FileSaver.saveAs(data, _saveFileNameString);
    //   }
    //   return res;
    // });
  }

  private sendWhatsAppMessage(conversationId: string, message: WAMessage): Promise<any> {
    const formData = new FormData();
    formData.append('conversationId', conversationId);
    formData.append('type', message.type);
    formData.append('text', message.text);
    if (message.attachment) formData.append('file', message.attachment.file);
    return this.apiService.postRequest('/agent/channels/whatsapp', formData);
  }

  private loadAgentsByIds(): Promise<void> {
    return this.apiService.getRequest('/agents/channels/calls/listAgents').then((res: any) => {
      if (res.result) res.content.forEach((agent:any) => this.agentsById[agent.id] = agent);
    });
  }
}
