import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appOnEndScroll]'
})
export class OnEndScrollDirective {

  @Output('appOnEndScroll') public endScroll = new EventEmitter<void>();
  @Input() public disableScroll = false;
  private previousScrollTop?: number;
  private isScrollingDown = true;

  constructor() { }

  @HostListener('scroll', ['$event'])
  public onEndScroll(event: any):void {
    this.isScrollingDown = !this.previousScrollTop || (this.previousScrollTop < event.target.scrollTop);
    this.previousScrollTop = event.target.scrollTop;
    // end of scroll reached
    if (
      !this.disableScroll
      && this.isScrollingDown
      && event.target.offsetHeight + event.target.scrollTop > event.target.scrollHeight - 100
    ) {
      this.endScroll.emit();
    }
  };
}
