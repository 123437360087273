<div class="status-bar error" *ngIf="status.error">
  <p>{{ status.error }}</p>
</div>
<div class="status-bar loading" *ngIf="!status.error && !status.class">
  <p>{{ 'LAYOUT.STATUS.LOADING' | translate }}</p>
  <span>.</span>
  <span>.</span>
  <span>.</span>
</div>
<div class="status-bar" *ngIf="!status.error && status.class">
  <div class="agent">
    <mat-icon class="status-username-icon" [ngClass]="status.class">label</mat-icon>
    <span class="name">{{ user | fullname }}&nbsp;|&nbsp;</span>
    <span class="id">ID: {{user.ccLogin}}</span>
    <span class="status" [ngClass]="status.class" *ngIf="connected">
      <span class="label">{{ status.current.replace('-', ' ') }}</span>
      {{ 'DIALPAD.STATUS.SINCE' | translate }}:&nbsp;
      <span class="time hours" *ngIf="timer.hours">
        {{timer.hours}}
      </span>
      <span class="time-separator" *ngIf="timer.hours">:</span>
      <span class="time min">
        {{timer.min}}
      </span><span class="time-separator">:</span><span class="time sec">
        {{timer.sec}}
      </span>
    </span>
  </div>
  <div class="on-call" *ngIf="!statusBarService.showDialpad && statusBarService.callInfo" (click)="switchDialpad()">
    <span innerHtml="{{ 'LAYOUT.STATUS.CALL_TO' | translate:statusBarService.callInfo }}"></span>
    <span class="timer">{{ statusBarService.callInfo.time }}</span>
  </div>
  <div class="actions">
    <button class="dialpad-button" [ngClass]="{ 'showing-dialpad': statusBarService.showDialpad }" mat-button (click)="switchDialpad()" id="status-bar-dialpad-button">
      <mat-icon [ngClass]="{'oncall': statusBarService.callInfo && !statusBarService.showDialpad}">dialpad</mat-icon>
    </button>
    <!-- <button mat-button id="status-bar-stats">
      <mat-icon>show_chart</mat-icon>
    </button> -->
    <button *ngIf="connected && !status.updating" mat-button [matMenuTriggerFor]="statuspicker" id="status-bar-status-button">
      <mat-icon class="button-status-icon" [ngClass]="status.class" [svgIcon]="statusIcons[status.class].label">
      </mat-icon>
    </button>
    <div class="status-loading" *ngIf="status.updating">
      <div class="spinner-container">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    </div>
    <button class="connected" *ngIf="connected" mat-button (click)="disconnect()" id="status-bar-disconnect-button">
      <mat-icon class="button-icon">power_settings_new</mat-icon>
    </button>
    <button class="disconnected" *ngIf="!connected" mat-button (click)="connect()" id="status-bar-connect-button">
      <mat-icon class="button-icon">power_settings_new</mat-icon>
    </button>
    <mat-menu #statuspicker="matMenu" class="menu-status-picker">
      <button class="status-picker-item Available" *ngIf="status.current !== 'Available'" mat-menu-item
        (click)="changeStatus('Available')" id="status-bar-change-status-available">
        <mat-icon [svgIcon]="statusIcons.available.label"></mat-icon>
        &nbsp;&nbsp;<span>{{ 'DIALPAD.STATUS.AVAILABLE' | translate }}</span>
      </button>
      <button class="status-picker-item On-Break" *ngIf="status.current !== 'On-Break'" mat-menu-item
        (click)="changeStatus('On-Break')" id="status-bar-change-status-on-break">
        <mat-icon [svgIcon]="statusIcons.onBreak.label"></mat-icon>
        &nbsp;&nbsp;<span>{{ 'DIALPAD.STATUS.ONBREAK' | translate }}</span>
      </button>
      <button class="status-picker-item Meeting" *ngIf="status.current !== 'Meeting'" mat-menu-item
        (click)="changeStatus('Meeting')" id="status-bar-change-status-meeting">
        <mat-icon [svgIcon]="statusIcons.meeting.label"></mat-icon>
        &nbsp;&nbsp;<span>{{ 'DIALPAD.STATUS.MEETING' | translate }}</span>
      </button>
      <button class="status-picker-item Outgoing" *ngIf="status.current !== 'Outgoing'" mat-menu-item
        (click)="changeStatus('Outgoing')" id="status-bar-change-status-outgoing">
        <mat-icon [svgIcon]="statusIcons.outgoing.label"></mat-icon>
        &nbsp;&nbsp;<span>{{ 'DIALPAD.STATUS.OUTGOING' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>
<div class=" dialpad" *ngIf="dialpadOptions" [ngClass]="{'opened': statusBarService.showDialpad}">
  <ziwo-dialpad style="max-height: 0px; display: block;" [contactCenterName]="dialpadOptions.contactCenterName"
    [authenticationToken]="dialpadOptions.token" (events)="onDialpadEvents($event)" mode="inapp" [parentNavigationEvents]="navigationEventsSubject.asObservable()">
  </ziwo-dialpad>
</div>