import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { ZiwoApiResult } from '../../api/core';
import { LoginService } from '../../public/login/login.service';
import { CurrentUserService, AgentStatus } from '../../services/current-user.service';
import { DialpadCoreService } from '../../services/dialpad-core.service';
import { Route, StatusBarService } from './status-bar.service';

interface Timer {
  interval:any;
  timerStart:Date;
  hours:string;
  min:string;
  sec:string;
}

interface StatusInfo {
  current:string;
  since:Date;
  class:string;
  updating:boolean;
  error:string;
}

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnInit {

  public connected = false;
  public dialpadOptions:any;
  public user = this.currentUserService.get();
  public timer!:Timer;
  public status:StatusInfo;
  public statusIcons: {[key: string]: {icon: string; label: string}} = {
    available: { icon: 'available', label: 'available' },
    onBreak: { icon: 'break', label: 'onBreak' },
    'on-break': { icon: 'break', label: 'on-break' },
    meeting: { icon: 'meeting', label: 'meeting' },
    outgoing: { icon: 'outgoing', label: 'outgoing' },
  };

  constructor(
    public statusBarService:StatusBarService,
    private loginService:LoginService,
    private accountService:AccountService,
    private currentUserService: CurrentUserService,
    private dialpadCoreService:DialpadCoreService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.setupTimer();
    this.startTimer();
    this.listenForStatus();
    this.status = this.getInitialStatusInfo();
    _.map(this.statusIcons, (mapping:any) => {
      this.matIconRegistry.addSvgIcon(
        mapping.label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/dialpad/${mapping.icon}.svg`)
      );
    });
  }

  public ngOnInit(): void {
    this.dialpadOptions = {
      contactCenterName: this.accountService.accountName,
      token: this.loginService.accessToken,
    };
  }

  public get navigationEventsSubject(): Subject<Route> {
    return this.statusBarService.navigationEventsSubject;
  }

  public onDialpadEvents(ev: any): void {
    if (ev.type === 'dialpad_error_connection') {
      this.status.error = ev.data;
      alert(ev.data);
    }

    this.dialpadCoreService.onDialpadEvents(ev);
  }

  public switchDialpad(): void {
    this.statusBarService.showDialpad = !this.statusBarService.showDialpad;
  }

  public changeStatus(status:AgentStatus|string): void {
    this.status.updating = true;
    this.statusBarService.updateStatus(status as AgentStatus).then(() => {
      this.setupTimer();
    }).catch(() => {
      console.warn('Error when trying to update the status');
      this.status.updating = false;
    });
  }

  public disconnect(): void {
    this.dialpadCoreService.getCore().then(c => {
      c.client.disconnect();
    }).catch(() => {});
  }

  public connect(): void {
    this.dialpadCoreService.getCore().then(c => c.connect()).catch(() => {});
  }

  private listenForStatus(): void {
    this.dialpadCoreService.getCore().then(r => {
      this.dialpadCoreService.agentSocket.on('GET /live/status', (data:ZiwoApiResult<any>) => {
        if (data.content.status === 'Logged Out') {
          this.connected = false;
          return;
        }
        this.connected = true;
        this.status.updating = false;
        this.status.current = data.content.status.replace(' ', '-');
        this.status.since = new Date();
        this.status.class = this.status.current.toLowerCase().replace(/ /g, '-');
        this.status.since.setSeconds(this.status.since.getSeconds() - data.content.since);
      });
      this.dialpadCoreService.agentSocket.emit('GET /live/status');
    }).catch(() => {});
  }

  private getInitialStatusInfo(): StatusInfo {
    return {
      current: AgentStatus.Available,
      since: new Date(),
      class: '',
      updating: false,
      error: ''
    };
  }

  private setupTimer(): void {
    this.timer = {
      hours: '00',
      min: '00',
      sec: '00',
      interval: undefined,
      timerStart: new Date(),
    };
    this.startTimer();
  }

  private startTimer():void {
    if (this.timer.interval) {
      window.clearInterval(this.timer.interval);
    }
    this.timer.interval = window.setInterval(() => {
      const now = new Date();
      const seconds:number = Math.floor(((now as any) - (this.timer.timerStart as any)) / 1000);
      const counter = new Date(seconds * 1000).toISOString().substr(11, 8).split(':');
      const hr:string = counter[0];
      const min:string = counter[1];
      const sec:string = counter[2];
      this.timer.hours = hr;
      this.timer.min = min;
      this.timer.sec = sec;
    }, 1000);
  }
}
